/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import $, { get, post } from 'jquery';
import * as script from '../../js';
import { Link } from 'react-router-dom';
// components
import Loading from '../../components/loading';
import NavBar from '../../components/navbar';
import Header from '../../components/header';
import Footer from '../../components/footer';

import { client } from '../../services/prismic';

const Home = () => {

        const [postsPreview, setPostsPreview] = useState([]);

        useEffect(() => {
            const getPost = async() => {
                
                const post = await client.getByType('ftblog', {
                    orderings: {
                        field: 'document.first_publication_date',
                        direction: 'desc',
                      },
                });
                console.log(post);
                setPostsPreview(post.results.slice(0,3));
            }
    
            getPost()
        }, [])

        const reduceSubtitle = (subtitle) => {
            return String(subtitle.slice(0, 180)).concat('...');
        }

    const loading = () =>{
        document.querySelector('body').className = 'stopSobre';
        document.querySelector('html').style.overflowY = 'hidden';
        setTimeout(() => {
   
             document.querySelector('.loading .logo_preloader').style.animation = 'falls 1s linear forwards';
           
            setTimeout(() => { document.querySelector('.cortina-esquerda').style.animation = 'cortina 0.8s ease-in-out forwards';
            document.querySelector('.cortina-direita').style.animation = 'cortina .8s ease-in-out forwards';
            document.querySelector('.loading').style.zIndex = '-100';
            document.querySelector('.box-header p').style.animation = 'fadeDown 1.2s 1s forwards';
            document.querySelector('.box-header h1').style.animation = 'scale 0.8s .65s forwards';
            document.querySelector('.box-header #btn-ativar').style.animation = 'scale 0.8s 1.8s forwards';
            document.querySelector('.nav .nav-items').style.animation = 'opacityLink 0.8s 2.5s forwards';}, 1700);
    
            setTimeout( () =>{

                    document.querySelector('html').style.overflowY = 'scroll';
                    document.querySelector('html').style.overflowX = 'hidden';
                    document.querySelector('.cortina-esquerda').style.border = 'none';
                    document.querySelector('.cortina-direita').style.border = 'none';
                  
                    document.querySelector('.loading').style.display = 'none';
         
    
            },2050)
            
        },300);
    }
    let slides = document.querySelectorAll('.slide');
    slides.forEach(slide =>{
        slide.classList.remove('stop');
    })
    
    $(document).ready( () =>{
	
        // carousel
            
            var slides = document.querySelectorAll('.slide');
            var btns = document.querySelectorAll('.btn-navigation');
            var currentSlide = 1;

            //Javascript for image slider manual navigation
            var manualNav = function(manual){

                slides.forEach((slide) =>{
                    slide.classList.remove('active');
                    btns.forEach((btn) =>{
                        btn.classList.remove('active');
                    });
                });

                slides[manual].classList.add('active');
                btns[manual].classList.add('active');
            }
        
            btns.forEach((btn, i) => {
                btn.addEventListener('click', () => {
                    console.log(i);
                    manualNav(i);
                    currentSlide = i;
                });
            })

            
            
           
            
            function increment(i, max, element){
                if(i > max) return;
                setTimeout(function(){
                    element.innerText = Math.round(i);
                    increment(i+(max/100), max, element);
                },10)
                }
            
                $(document).ready(()=>{
                    $('html, body').animate({ scrollTop: 0},0);
                    // animcoes com scroll
                    var targetMissao = document.querySelector('.missao');
                    var targetValores = document.querySelector('.valores');
                    var targetServicos = document.querySelector('.flex-servicos .box1');
                    var targetServicos3 = document.querySelector('.flex-servicos .box3');

            
                    var offset = $(window).height() * 3/4;
                    var offset2 = $(window).height() * 2/4;
                    var offset3 = $(window).height() * 2.5/4;
            
                    var documentTop = 0;
                    var animeScroll = () =>{
                        
                            documentTop = $(document).scrollTop();
                            var itemMissao = $(targetMissao).offset().top;
                            var itemValores = $(targetValores).offset().top;
                            var sectionServicos = $(targetServicos).offset().top;
                            var sectionServicos3 = $(targetServicos3).offset().top;
                        
                        //MISSAO VALORES E VISAO
                        
                            if(documentTop > itemMissao - offset){
                                document.querySelector('.missao').classList.add('missao-start')
                                document.querySelector('.visao').classList.add('visao-start')
                            }else{
                                document.querySelector('.missao').classList.remove('missao-start')
                                document.querySelector('.visao').classList.remove('visao-start')
                            }
                           
                           
                            if(documentTop > itemValores - offset){
                                document.querySelector('.valores').classList.add('valores-start')
                            }else{
                                document.querySelector('.valores').classList.remove('valores-start')
                            }
                            
                            
                               
                              
                  
            
                        // SERVICOS
                        let fronts = document.querySelectorAll('.front');
                        let backs = document.querySelectorAll('.back');
                        if(documentTop > sectionServicos - offset3){
                            
                            fronts.forEach(front =>{
                                front.classList.remove('front180');
                            });
                            backs.forEach(back =>{
                                back.classList.remove('back0');
                            });

                    
                        }else{
                            fronts.forEach(front =>{
                                front.classList.add('front180');
                            });
                            backs.forEach(back =>{
                                back.classList.add('back0');
                            });
                            
                        }

                        if(documentTop > sectionServicos3 - offset3){
                            document.querySelector('.box3 .front').classList.remove('front180')
                            document.querySelector('.box3 .back').classList.remove('back0')
                        }else{
                            document.querySelector('.box3 .front').classList.add('front180')
                            document.querySelector('.box3 .back').classList.add('back0')
                        }
                    
                    }
                    
                           $(document).scroll(()=>{
                                if(!document.querySelector('body').classList.contains('stopHome') ){
                                    animeScroll();
                                } 
                            })
                   
                 
                
                });

                // animation
                //all the stops
                        let stops = document.querySelectorAll("#bottom-to-top stop");
                        let start = null;
                        let progress = 0;//the progress of the animation
                        let duration = 2000; //2 seconds in millisecinds
                        let rid = null;//request animation id

                        function Frame(timestamp) {
                        rid = window.requestAnimationFrame(Frame);
                    
                        if (!start) start = timestamp;
                        progress = timestamp - start;
                        
                        if (progress <= duration) {
                        //for each stop reset the offset attribute
                            stops.forEach(stop => {
                            stop.setAttributeNS(null, "offset", progress / duration);
                            });
                        }
                        // if the animation is over cancel the animation
                        if (progress >= duration){window.cancelAnimationFrame(rid)}
                        }
                        
                loading();
                Frame();
                $('#btn-ativar').on('click', e => {
                    // e.preventDefault()
                    
                    var target = document.querySelector('#btn-rolar');
                    // alert(idd);
                       var targetOffset = $(target).offset().top;
                        //   alert(targetOffset);
                        var posicaoFinal = targetOffset - 120;
                    $('html,body').animate({ 
                        scrollTop: targetOffset - 120
                    }, 500);
                });
	});

    return(
        <> 
           
            <Loading />
            <NavBar hideNavbar/>
            <div >
                <Header />
                
                        {/* <!-- section sobre Nos --> */}
                <section className="sobre-nos" >

                    <div className="flex-sobre">
                        <div className="missao">
                            <h1>
                                Missão
                            </h1>

                            <p>
                                Promover soluções e experiências inovadoras e sustentáveis  buscando sempre a melhor tecnologia para aplicação.
                            </p>
                        </div>

                        <div className="visao">
                            <h1>
                                Visão
                            </h1>

                            <p>
                                Sermos reconhecidos como uma empresa que oferece produtos e serviços de qualidade que supera expectativas dos clientes.
                            </p>
                        </div>

                        <div className="valores">
                            <h1>
                                Valores
                            </h1>
                            <ul>
                                <li><p>• Ética</p></li>
                                <li><p>• Profissionalismo</p></li>
                                <li><p>• Sustentabilidade</p></li>
                                <li><p>• Excelência de Atendimento</p></li>
                                <li><p>• Satisfação</p></li>
                        
                            </ul>
                        </div>

                    </div>

                </section>	
            {/* <!--xxxx section sobre Nos xxxx--> */}

            {/* <!-- SECTION SERVIÇOS --> */}
                <section className="servicos">

                        <h1 className="tituloSection">Serviços</h1>

                    <section className="flex-servicos">

                        <div className="box1">
                            <div className="overlay-servico front">
                                <h2>Energia solar</h2>
                                <p>Instale seu sistema de Energia Solar e gere 
                                energia limpa e sustentável com economia.</p>
                                <a href="#" className="btn btn-saiba-mais mt-50">Saiba Mais</a>
                            </div>
                            <div className="back"></div>
                        </div>

                        <div className="box2">
                            <div className="overlay-servico front ">
                                <h2>Instalações Elétricas</h2>
                                <p>
                                    Instalações Elétricas
                                    Projetos elétricos especializados prediais e industriais
                                </p>
                            <a href="#" className="btn btn-saiba-mais mt-50">Saiba Mais</a>
                            </div>
                            <div className="back"></div>
                        </div>

                        <div className="box3">
                            <div className="overlay-servico front f3">
                                <h2>Consultoria</h2>
                                <p>Solicite nossa Consultoria e conheça as bandeiras tarifárias.</p>
                                <a href="#" className="btn btn-saiba-mais mt-50">Saiba Mais</a>
                            </div>
                            <div className="back b3"></div>
                        </div>

                    </section>

                    <a href="#" className="btn btn-veja-outros-servicos mt-50">Veja outros serviços</a>
                </section>
            {/* <!--XXX SECTION SERVICOS XXX --> */}

            {/* <!-- SECTION COUNTER INFORMATION --> */}
           
            {/* <!-- XXX SECTION COUNTER INFORMATION XXX--> */}

            {/* <!-- SECTION INSTALAÇÕES --> */}
                <section className="instalacoes">
                    <h1 className="tituloSection">Nossas Instalações</h1>
                    <h2 className="sub-titulo">Conheça nossas principais instalações</h2>

                    {/* <!-- carousel --> */}
                    <div className="carousel" onLoad={
                        ()=>{
                           script.Repeat();
                        }
                    }>

                        <div className="img-slider">

                            <div className="slide active">
               
                                <img src="./img/instalacoes/instalacao5.png"/>
                                
                                <div className="info">
                                    <h2>Slide 01</h2>
                                </div>
                            </div>

                            <div className="slide">
                                <img src="./img/instalacoes/instalacao2.jpg"/>
                                <div className="info">
                                    <h2>Slide 02</h2>
                                </div>
                            </div>

                            <div className="slide">
                                <img src="./img/instalacoes/instalacao3.jpg"/>
                                <div className="info">
                                    <h2>Slide 03</h2>
                                </div>
                            </div>

                            <div className="slide">
                                <img src="./img/instalacoes/instalacao4.png"/>
                                <div className="info">
                                    <h2>Slide 04</h2>
                                </div>

                            </div>

                            <div className="slide">
                                <img src="./img/instalacoes/instalacao1.png" />
                                <div className="info">
                                    <h2>Slide 05</h2>
                                </div>
                            </div>

                    
                            <div className="slide">
                                <img src="./img/instalacoes/instalacao7.jpg" />
                                <div className="info">
                                    <h2>Slide 06</h2>
                                </div>
                            </div>

                            <div className="navigation">
                                <div className="btn-navigation active"></div>
                                <div className="btn-navigation"></div>
                                <div className="btn-navigation"></div>
                                <div className="btn-navigation"></div>
                                <div className="btn-navigation"></div>
                                <div className="btn-navigation"></div>
                            </div>

                        </div>

                    </div>
                        

                    {/* <!-- xxx carousel xxx --> */}


                </section>
            {/* <!-- XXX SECTION INSTALAÇÕES XXX --> */}

            {/* <!-- SECTION BLOG  --> */}
                <section className="blog">
                    <h1 className="tituloSection">Blog</h1>
                    <h2 className="sub-titulo">Confira as últimas novidades sobre Energia Solar</h2>

                    <div className="flex-novidades">
                        {
                            postsPreview.map(post => (
                                <article className="card" key={post.id}>
                            
                                    <img src={post.data.bannerpreview.url} className='card-image' alt={post.data.banner.alt} />
                                    
                                    <h3>{post.data.title.length <= 67 ? post.data.title.slice(0,67) : post.data.title.slice(0,67).concat('...') }</h3>
                                    <p className="contentCard">{reduceSubtitle(post.data.subtitle)}</p>
                                    <Link to={{pathname: `/artigos/${post.uid}`, state:{titulo: 'Casas de energia solar', sub: 'Você já reparou em casas com enormes janelas escuras quando você passa de carro?'}}}>Saiba mais</Link>
                                </article>
                            ))
                        }


                    </div>
                </section>

            {/* <!-- xxx SECTION BLOG xxx --> */}

            {/* <!-- section INTERESSOU --> */}
                <section className="interessou">
                    <h1 className="tituloInteressou">Se interessou por algum de nossos serviços? <br />Entre em contato conosco</h1>
                    <h2 className="sub-interessou">SOLICITE HOJE UM ORÇAMENTO</h2>

                    <a target="_blank" rel="noreferrer"  href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`} className="btn btn-fale-conosco">Fale Conosco <i className="fab fa-whatsapp"></i></a>
                </section>

            {/* <!-- XXX section INTERESSOU XXX --> */}
                <div className="wpp-fixo">
                <a  target="_blank" rel="noreferrer"  href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`}><i className="fab fa-whatsapp fa-2x wpp"></i></a>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Home;