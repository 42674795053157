import React from 'react';
import '../../styles/app.css';

const Titulo = ({titulo, sub, image = "", status =""}) =>{
     return(
          
          <div className="containerTitulo" style={{backgroundImage: image}}>
               <div className="overlay-container-titulo">
                    <svg viewBox="0 0 2600 131.1" preserveAspectRatio="none">
                         <path className="elementor-shape-fill"style={{'fill': '#fff'}} d="M0 0L2600 0 2600 69.1 0 0z"></path>
                         <path className="elementor-shape-fill" style={{'fill': '#fff', "opacity":"0.5"}} d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
                         <path className="elementor-shape-fill" style={{'fill': '#fff', "opacity":"0.25"}} d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
                    </svg>
               </div>
               <h1 className={`tituloPages ${status}`}>{titulo}</h1>
               <p className="sobrePages">{sub}</p>
              
          </div>
          
     )
}

export default Titulo;