/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import * as script from '../../js';


// components
import NavBar from '../../components/navbar';
import Titulo from '../../components/titulos';
import Footer from '../../components/footer'
import { client } from '../../services/prismic';
import Loading from '../../components/loading';
import styles from './styles.module.css';


import { asHTML } from '@prismicio/helpers';
import { FiUser, FiCalendar, FiClock } from 'react-icons/fi'
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import logo from '../../img/logo.jpg'
import loadingGif from '../../img/loading.gif'

const Artigos = (state) => {
    const [post, setPost] = useState();


    const titulo = state.location.state.titulo;
    const subTitle = state.location.state.sub;
    // STOPANDO SLIDER HOME PRA NÃO BUGAR
    let slides = document.querySelectorAll('.slide');
    slides.forEach(slide => {
        slide.classList.add('stop');
    })
    document.body.style.overflowY = 'auto';
    // stopando animacoes de scrolls de outra pagina para nao gerar erro;
    document.querySelector('body').className = 'stopHome stopSobre';
    $('html, body').animate({ scrollTop: 0 }, 100);


    useEffect(() => {
        const uuid = document.location.pathname.replace('/artigos/', '');
        const getPost = async () => {
            const post = await client.getByUID('ftblog', uuid)
            setTimeout(() => {
                setPost(post);
                console.log(post);
            }, 1500)

        }
        getPost();
    }, [])

    if (post) {
        const readingTime = () => {
            let arr = post.data.content;
            const words = arr.map((content) => {
               const body = content.body1.filter(item => item.type !== "image").map(item => item.text.split(" ").length);
                const heading = content.heading && content.heading.split(" ").length;
                
                return [heading, ...body]
            }).flat().reduce((ac, item) => {
                ac += item;
                return ac;
            }, 0)
            
            return Math.ceil(words / 200);
        }

        const time = readingTime();
        const background = post.data.banner.url;
        return (
            <>
                <NavBar />
                <Titulo status='hidden' image={background && `url(${post.data.banner.url})`} />
                <div className="containerArtigos" style={{ overflowX: 'hidden' }}>



                    <div className={styles.arrow_blog}>
                        <img src={require('../../img/icons/corner-down-left.svg').default} width={50} />
                        <h4 style={{ fontWeight: 'bold', letterSpacing: '1px' }}>Voltar ao blog</h4>
                    </div>

                    <main className={styles.container}>
                        <div>
                            <h1>{post.data.title}</h1>
                            <div className={styles.detailsPost}>
                                <span><FiCalendar />{format(new Date(post.last_publication_date), 'dd MMM yyyy', {
                                    locale: ptBR
                                })}</span>
                                <span><FiUser />{post.data.author}</span>
                                <span><FiClock /> {time} min</span>
                            </div>
                        </div>

                        <article className={styles.content}>
                            {post.data.content && post.data.content.map((content, index) => {

                                return (
                                    <div className={styles.group} key={`${content.body1[0]?.text.slice(0, 10)}${index}`}>
                                        <h2 className={styles.heading}>{content.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: asHTML(content.body1) }}></div>
                                    </div>

                                )
                            })}
                        </article>

                    </main>

                </div>




                <Footer />
            </>
        )

    } else {
        const loading = () => {
            document.querySelector('body').className = 'stopSobre';
            document.querySelector('html').style.overflowY = 'hidden';
            setTimeout(() => {

                document.querySelector('.loading .logo_preloader').style.animation = 'falls 1s linear forwards';

                setTimeout(() => {
                    document.querySelector('.cortina-esquerda').style.animation = 'cortina 0.8s ease-in-out forwards';
                    document.querySelector('.cortina-direita').style.animation = 'cortina .8s ease-in-out forwards';
                    document.querySelector('.loading').style.zIndex = '-100';
                    document.querySelector('.box-header p').style.animation = 'fadeDown 1.2s 1s forwards';
                    document.querySelector('.box-header h1').style.animation = 'scale 0.8s .65s forwards';
                    document.querySelector('.box-header #btn-ativar').style.animation = 'scale 0.8s 1.8s forwards';
                    document.querySelector('.nav .nav-items').style.animation = 'opacityLink 0.8s 2.5s forwards';
                }, 1700);

                setTimeout(() => {

                    document.querySelector('html').style.overflowY = 'scroll';
                    document.querySelector('html').style.overflowX = 'hidden';
                    document.querySelector('.cortina-esquerda').style.border = 'none';
                    document.querySelector('.cortina-direita').style.border = 'none';

                    document.querySelector('.loading').style.display = 'none';


                }, 2050)

            }, 50);
        }
        loading();

        return (
            <Loading />
        )
    }



}

export default Artigos