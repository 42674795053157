
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import logo from '../../img/logo.jpg'
import loading from '../../img/loading.gif'

const Loading = () => {
    return(
        <div className="preloader"> 
			<div className="loading">
				
			<div className="animate-svg2">
				<img src={logo} width="130" className='logo_preloader'/>
			</div>
				<img src={loading} width="80" />
			</div>
			<div className="cortina-esquerda"></div>
			<div className="cortina-direita"></div>
		</div>
    )
}


export default Loading;