/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react'

import $ from 'jquery'

// Components
import NavBar from '../../components/navbar';
import Titulo from '../../components/titulos';
import Footer from '../../components/footer'
const Servicos = () =>{
      // STOPANDO SLIDER HOME PRA NÃO BUGAR
    let slides = document.querySelectorAll('.slide');
    slides.forEach(slide =>{
        slide.classList.add('stop');
    })
    document.body.style.overflowY = 'auto';
    // stopando animacoes de scrolls de outra pagina para nao gerar erro;
        document.querySelector('body').className = 'stopHome stopSobre';
        $('html, body').animate({ scrollTop: 0},100);
    
    
     // animação com scroll
          $(document).ready(()=>{
               var fila1 = document.querySelector('.content-servicos .box-serv1');
               var fila2 = document.querySelector('.box5');

               var offset = $(window).height() * 2/4;

               function animationServico(){
                    let documentTop = $(document).scrollTop();
                    let boxfila1 = $(fila1).offset().top;
                    let boxfila2 = $(fila2).offset().top;

                    if(documentTop > boxfila1 - offset){
                         
                         document.querySelectorAll('.b1').forEach(box =>{
                              box.style.animation = 'fade 2.5s ease-in-out forwards';
                         })
                    }
                    if(documentTop > boxfila2 - offset){
                        
                         document.querySelectorAll('.b2').forEach(box =>{
                              box.style.animation = 'fade 2.5s ease-in-out forwards';
                         })
                    }
               }

               $(document).scroll(()=>{
                    animationServico();
               })

          })

     return (
          <>
          <NavBar />
          <Titulo titulo='Serviços' sub='Veja nossos serviços e solicite um orçamento'/>
          
               <section className="section-servicos-page-servicos">
                
               </section>
                   <div className="title-servicos">
                         <h1>Nossos Servicos</h1>
                         <h2>Veja mais detalhes dos nossos serviços</h2>
                   </div>

                    <div className="container">
                         <div className="content-servicos">
                              
                              <div className="box b1 box-serv1" >
                                   <img src={require('../../img/servicos/icon1.png').default}  width="80" alt="energia-solar" />
                                   <h1>Energia Solar</h1>
                                   <p>Projetos e instalações em energia solar residenciais, comerciais e industrial.</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento sobre energia solar.`}>Orçamento</a>
                              </div>
                              
                              
                              <div className="box b1 box-serv2">
                                   <img src={require('../../img/servicos/icon2.png').default} width="80" />    
                                   <h1>Automação industrial</h1>
                                   <p>Controle de processos Industriais (Nível, Vazão, Pressão, Temperatura). Desenvolvimento de projetos de acordo com a necessidade do cliente.</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento sobre automação industrial`}>Orçamento</a>
                              </div>
                              <div className="box b1 box-serv3">
                                   <img src={require('../../img/servicos/icon3.png').default} width="80" />
                                   <h1>Instalações Elétricas</h1>
                                   <p>Projetos, manutenções e instalações elétricas prediais e industriais.</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento sobre instalações elétricas`}>Orçamento</a>
                              </div>
                              <div className="box b1 box4">
                                    <img src={require('../../img/servicos/icon4.png').default} width="80" />
                                    <h1>Instrumentação Industrial</h1>
                                    <p>Consultoria em Instrumentação, elétrica e automação;</p>
                                    <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento no ramo de instrumentação industrial.`}>Orçamento</a>
                              </div>
                              <div className="box b2 box5">
                                   <img src={require('../../img/servicos/icon5.png').default} width="80" />
                                   <h1>Aumento de Carga</h1>
                                   <p>Projeto e instalações de Padrão de entrada de instalações novas e existentes.</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento sobre aumento de carga.`}>Orçamento</a>
                              </div>
                              <div className="box b2 box6">
                                   <img src={require('../../img/servicos/icon6.png').default} width="80" />
                                   <h1>Correção do Fator de Potência</h1>
                                   <p>Montagem do banco de capacitores para a correção do fator de potência</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento`}>Orçamento</a>
                              </div>
                              <div className="box b2 box7">
                                   <img src={require('../../img/servicos/icon7.png').default} width="80" />
                                   <h1>Consultoria Bandeira tarifária</h1>
                                   <p>Saiba como funciona as taxas e cobrança em sua conta de energia elétrica.</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento`}>Orçamento</a>
                              </div>
                              <div className="box b2 box8">
                                   <img src={require('../../img/servicos/icon8.png').default} width="80" />
                                   <h1>Estudo de qualidade de energia</h1>
                                   <p>Monitoramento da qualidade de energia, para assegurar ao consumidor que a energia que ele está adquirindo apresenta características adequadas</p>
                                   <a className="btn" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? Gostaria de fazer um orçamento.`}>Orçamento</a>
                             
                              </div>
                         </div>
                    </div>
      
                    
                    <div className="wpp-fixo">
                     <a href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`}  rel="noreferrer" target="_blank" ><i className="fab fa-whatsapp fa-2x wpp"></i></a>
                </div>
                  
          <Footer/>
          </>
     )
}

	


export default Servicos;