/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line */

import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = ({hideNavbar}) => {

    //menu-mobile

    const showMenu = () =>{
        let navLinks = document.querySelector('.nav .nav-items');
    let socialIcons = document.querySelector('.nav .social');
       
        navLinks.style.right = '0vw';
        socialIcons.style.right = '0vw';
        document.querySelector('.nav').style.backgroundColor = 'rgba(255,255,255)';
        document.querySelector('.nav').style.height = 'auto';
        document.querySelector('.nav .nav-menu i').style.color = 'rgb(111, 108, 108) !important';
        document.querySelector('.nav i').style.color = 'rgb(111, 108, 108) !important';
        document.querySelector('.bars').style.opacity = '0';
        document.querySelector('.close').style.opacity = '1';
            
    }

    const hideMenu = () =>{
        let navLinks = document.querySelector('.nav .nav-items');
    let socialIcons = document.querySelector('.nav .social');
        navLinks.style.right = '100vw';
        socialIcons.style.right = '100vw';
        document.querySelector('.bars').style.opacity = '1';
        document.querySelector('.nav').style.backgroundColor = 'transparent';
        document.querySelector('.close').style.opacity = '0';
        document.querySelector('.nav').style.height = '80px';
    }
    // fim menu-mobile

    return(
        
        <div className="nav">
        
            <div className="overlay-nav-mobile">
                    
                <div className="nav-menu flex-row">
                    <div className="nav-brand">
                    {!hideNavbar ? <NavLink exact to="/" className="text-gray">
                           <img src={require('../../img/logoft.png').default} width="70" alt="logo"/>
                        </NavLink> : <img src={require('../../img/logoft.png').default} width="70" alt="logo"/> }
                    </div>

                    <div>
                        <i className="fas fa-times close fa-2x" onClick={() => {hideMenu()}}></i>
                        <ul className="nav-items">
                        {!hideNavbar && <li className="nav-link">
                                
                           <NavLink exact to="/">Home</NavLink>
                            </li>}
                            <li className="nav-link">
                              <NavLink exact to="/sobre-nos">Sobre Nós</NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink exact to="/servicos">Serviços</NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink exact to="/instalacoes">Instalações</NavLink>
                            </li>
                      
                        </ul>
                        <i className="fas fa-bars bars fa-2x hamb-bars" onClick={() => {showMenu()}}></i>
                    </div>

                    <div className="social text-gray">
                        <a href="https://www.youtube.com/channel/UCAgxLCg-PpTOCiERia0nSGA" rel="noreferrer" target="_blank" className="youtube"><i className="fab fa-youtube youtube"></i></a>
                        <a href="https://www.instagram.com/energiaft/" rel="noreferrer" target="_blank"  className="instagram"><i className="fab fa-instagram "></i></a>
                    </div>
                </div>
            </div>
        
 	    </div>
      
    )
}

export default NavBar;