/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React from 'react';
import $ from 'jquery'
import ReactPlayer from 'react-player';
// Componemtes
import NavBar from './../../components/navbar/index';
import Footer from './../../components/footer/index';
import Titulo from './../../components/titulos/index';
import YouTubePlayer from 'react-player/youtube';

const Instalacoes = () =>{
     // STOPANDO SLIDER HOME PRA NÃO BUGAR
     let slides = document.querySelectorAll('.slide');
     slides.forEach(slide =>{
         slide.classList.add('stop');
     })
     document.body.style.overflowY = 'auto';
     // stopando animacoes de scrolls de outra pagina para nao gerar erro;
         document.querySelector('body').className = 'stopHome stopSobre';
         $('html, body').animate({ scrollTop: 0},100);

        setTimeout(() => {
            var menuTabs = document.querySelector('.menu-tabs');
       
            menuTabs.addEventListener('click', e =>{
            if(e.target.classList.contains("menu-tab-item") && !e.target.classList.contains('active')){
                const target = e.target.id;
                //pondo estilo na opção ativada
                menuTabs.querySelector('.activeProject').classList.remove('activeProject');
                e.target.classList.add('activeProject');

                /* removendo a classe activeProject de todos antes de selecionar
                 o clicado */
                let projetos = document.querySelector('.projetos');
                let serRemovido = projetos.querySelectorAll('.activeProject');
                serRemovido.forEach(removido =>{
                    removido.classList.remove('activeProject');
                })
                // selecionando todos ou 1 a 1
                if(target === 'todos'){
                    let itens = projetos.querySelectorAll('.box-img'); 
                    itens.forEach(item =>{
                        item.classList.add('activeProject');
                    })
                }else{
                    let itens = projetos.querySelectorAll(`.${target}`);
                    itens.forEach(item =>{
                        item.classList.add('activeProject');
                    })
                }
            } //fim do primeiro if
               
            });
        }, 2000);
        

    return (
        <>
            <NavBar/>
            <Titulo titulo={'Instalações'} sub={'Veja aqui nossas obras e projetos'} />

          
          
<section className="projetos-instalacoes">
                <h1>Nossos Projetos e Instalações</h1>
                <h3>Conheça alguns dos nossos projetos e instalações</h3>

                <ul className="menu-tabs">
                    <li><a className="menu-tab-item activeProject" id="todos">Todos</a></li>
                    <li><a className="menu-tab-item" id="guapimirim">Guapimirim</a></li>
                    <li><a className="menu-tab-item" id="mage">Magé</a></li>
                    <li><a className="menu-tab-item" id="saojoao">São João do Meriti</a></li>
                    <li><a className="menu-tab-item" id="niteroi">Niterói</a></li>
                    <li><a className="menu-tab-item" id="itaborai">Itaboraí</a></li>
                </ul>
                <section className="projetos">
                    <div className="box-img box-img1 activeProject guapimirim">
                        <div className="legenda">
                            <p>10,88kW - Guapimirim</p>
                        </div>
                    </div>
                    <div className="box-img box-img2 activeProject mage">
                        <div className="legenda">
                            <p>17,44kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img3 activeProject mage">
                        <div className="legenda">
                            <p>17,44kW - Magé</p>
                        </div>
                    </div>

                    <div className="box-img box-img4 activeProject mage">
                        <div className="legenda">
                            <p>17,44kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img5 activeProject mage">
                        <div className="legenda">
                            <p>14,88kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img6 activeProject mage">
                        <div className="legenda">
                            <p>14,44kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img7 activeProject guapimirim">
                        <div className="legenda">
                            <p>10,88kW - Guapimirim</p>
                        </div>
                    </div>
                    <div className="box-img box-img8 activeProject saojoao">
                        <div className="legenda">
                            <p>8,82kW - São João do Meriti</p>
                        </div>
                    </div>
                    <div className="box-img box-img9 activeProject saojoao">
                        <div className="legenda">
                            <p>8,82kW - São João do Meriti</p>
                        </div>
                    </div>

                    <div className="box-img box-img10 activeProject niteroi">
                        <div className="legenda">
                            <p>7,2kW - Niterói</p>
                        </div>
                    </div>
                    <div className="box-img box-img11 activeProject niteroi">
                        <div className="legenda">
                            <p>7,2kW - Niterói</p>
                        </div>
                    </div>
                    <div className="box-img box-img12 activeProject  mage">
                        <div className="legenda">
                            <p>5,115kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img13 activeProject mage">
                        <div className="legenda">
                            <p>5,2kW - Magé</p>
                        </div>
                    </div>

                    <div className="box-img box-img14 activeProject mage">
                        <div className="legenda">
                            <p>5,2kW - Magé</p>
                        </div>
                    </div>
                    <div className="box-img box-img15 activeProject itaborai">
                        <div className="legenda">
                            <p>3,28kW - Itaboraí</p>
                        </div>
                    </div>
                    
                    

                    
                </section>
            </section>
            <div className="parallax-instalacoes"></div>
            <section className="nossos-projetos" >
                <div className="text">
                    <h1>Nossos Projetos</h1>
                    <h2>Prezamos pela qualidade e preço justo</h2>

                    <p>Entre em contato conosco e solicite um orçamento</p>

                    <a className="btn" target="_blank" rel="noreferrer"  href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`}>Orçamento</a>
                </div>
                <video width="650" height="368" controls autoPlay muted>
               <source  src={require('../../video/video.mp4').default} type="video/mp4" />
             
                </video>
            </section>
            <div className="wpp-fixo">
            <a  target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`}><i className="fab fa-whatsapp fa-2x wpp"></i></a>
            </div>
            <Footer/>
        </>
    )
}

export default Instalacoes;