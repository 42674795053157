/* eslint-disable-next-line */
import $ from 'jquery';


	$(document).ready(()=>{
		$('html, body').animate({ scrollTop: 0},600);

	})
	
	

	// nav ao rolar scroll
		$(window).on("scroll",function(){

			if ($(window).scrollTop() > 80) {
				document.querySelector('.nav').classList.add('nav_rolate')
		
			} else { 
				document.querySelector('.nav').classList.remove('nav_rolate')
				document.querySelector('.nav').style.background = 'transparent';
			}

		});
	// 
	
	
		 // Javascript for image slider autoplay navigation
				//chamando no onload do home
				var Repeat = function(){
					var slides = document.querySelectorAll('.slide');
					var btns = document.querySelectorAll('.btn-navigation');

				let active = document.getElementsByClassName('active');
				let i = 1;
			
				let repeater = () =>{setTimeout(function(){
					[...active].forEach((activeSlide) => {
						activeSlide.classList.remove('active');
					});
				
					slides[i].classList.add('active');
					btns[i].classList.add('active');
					i++

					if(slides.length === i){
						i = 0;
					}
					if(i >= slides.length){
						return;
					};
				
					
					if(slides[i].className === 'slide'){
						repeater();
					}

					if(slides[i].className === 'slide stop'){
			
						
						return;
					}
					
					
				}, 6000);
				}
				
				repeater();
			}
			

		export {Repeat};
	 

           
	


	




