/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../../js/index';
import $ from 'jquery';


// components
import NavBar from '../../components/navbar';
import Titulo from '../../components/titulos';
import Footer from '../../components/footer'
const Sobre = () => {
    // STOPANDO SLIDER HOME PRA NÃO BUGAR
    let slides = document.querySelectorAll('.slide');
    slides.forEach(slide =>{
        slide.classList.add('stop');
    })
    document.body.style.overflowY = 'auto';
    // stopando animacoes de scrolls de outra pagina para nao gerar erro;
        document.querySelector('body').className = 'stopHome';
        $('html, body').animate({ scrollTop: 0},100);
    
    // animacoes dessa pag
     $(document).ready(()=>{
        var target = document.querySelector('.empresa-text-img img');
        var target2 = document.querySelector('.visao-sobre');
        var target3 = document.querySelector('.missao-sobre');
        var target4 = document.querySelector('.valores-sobre');
        var target2mobile = document.querySelector('.visao-sobre-mobile');
        var target3mobile = document.querySelector('.missao-sobre-mobile');
        var target4mobile = document.querySelector('.valores-sobre-mobile');
        
        var offset2 = $(window).height() * 2/4;
            
        var documentTop = 0;
        var animeScrollSobre = () =>{
                            
            documentTop = $(document).scrollTop();
            var itemTop = $(target).offset().top;
                                    
                if(documentTop > itemTop - offset2){        
                     document.querySelector('.empresa-text-img img').classList.add('sobre-start')
                }
                else{
                    document.querySelector('.empresa-text-img img').classList.remove('sobre-start')             
                }  
            
            // desktop
            var itemTop2 = $(target2).offset().top;
                                    
            if(documentTop > itemTop2 - offset2){        
                document.querySelector('.visao-sobre img').classList.add('right-to-left')
            }
            else{
                 document.querySelector('.visao-sobre img').classList.remove('right-to-left')             
            }      

            var itemTop3 = $(target3).offset().top;

            if(documentTop > itemTop3 - offset2){        
                document.querySelector('.missao-sobre img').classList.add('left-to-right')
            }
            else{
                 document.querySelector('.missao-sobre img').classList.remove('left-to-right')             
            }     
            
            var itemTop4 = $(target4).offset().top;
                                    
            if(documentTop > itemTop4 - offset2){        
                document.querySelector('.valores-sobre img').classList.add('right-to-left')
            }
            else{
                 document.querySelector('.valores-sobre img').classList.remove('right-to-left')             
            }    
            
            // mobile
            var itemTop2Mobile = $(target2mobile).offset().top;
                                    
            if(documentTop > itemTop2Mobile - offset2){        
                document.querySelector('.visao-sobre-mobile img').classList.add('right-to-left')
            }
            else{
                 document.querySelector('.visao-sobre-mobile img').classList.remove('right-to-left')             
            }      

            var itemTop3Mobile = $(target3mobile).offset().top;

            if(documentTop > itemTop3Mobile - offset2){        
                document.querySelector('.missao-sobre-mobile img').classList.add('left-to-right')
            }
            else{
                 document.querySelector('.missao-sobre-mobile img').classList.remove('left-to-right')             
            }     
            
            var itemTop4Mobile = $(target4mobile).offset().top;
                                    
            if(documentTop > itemTop4Mobile - offset2){        
                document.querySelector('.valores-sobre-mobile img').classList.add('right-to-left')
            }
            else{
                 document.querySelector('.valores-sobre-mobile img').classList.remove('right-to-left')             
            }    

            
        }
        $(document).scroll(()=>{
            if(!document.querySelector('body').classList.contains('stopSobre')){
                animeScrollSobre();
            }
        })
     })
                            // fim animacao

    return(
        <> 
             <NavBar/>
            <div >
                <Titulo titulo={'Sobre nós'} sub='Saiba mais sobre a nossa empresa'/>
                  {/* Apresentação */}
                  <section className="empresa-sobre-nos ">
                      <h1>A Empresa</h1>
                      <div className="empresa-text-img">
                          <p>
                          A FT energia atua no mercado oferecendo serviços de instalação, manutenção e consultorias nas áreas de elétrica, automação e instrumentação.<br/><br/>
                          Nosso objetivo é entregar ao cliente as melhores soluções do mercado, com um melhor custo benefício. <br/><br/>Procuramos atender nas mais diversas áreas, com mão-de-obra selecionada, treinada e qualificada, agregando experiência e custo benefício, com agilidade e eficiência.
                          </p>

                          <img src={require('../../img/sobreNos/img1.jpg').default} />
                      </div>

                  </section>

                  <section className="valores-sobre-nos valores-desktop">
                        <div className="visao-sobre">
                            <img src={require('../../img/sobreNos/img2.jpg').default} />
                            <div className="visao-text">
                                <h1>
                                    Visão
                                </h1>
                                <p>Sermos reconhecidos como uma empresa que oferece produtos
                                 e serviços de qualidade que supera expectativas dos clientes.</p>
                            </div>
                        </div>

                        <div className="missao-sobre">
                            <div className="missão-text">
                                <h1>
                                    Missão
                                </h1>
                                <p>Promover soluções e experiências inovadoras e 
                                    sustentáveis buscando sempre a melhor tecnologia para aplicação.</p>
                            </div>
                            <img src={require('../../img/sobreNos/img3.jpg').default} />
                        </div>

                        <div className="valores-sobre">
                            <img src={require('../../img/sobreNos/img4.jpg').default} />
                            <div className="valores-text">
                                <h1>
                                    Valores
                                </h1>
                             
                                <ul>
                                    <li><p id="etica"> Ética</p></li>
                                    <li><p id="profissionalismo"> Profissionalismo</p></li>
                                    <li><p id="sustentabilidade"> Sustentabilidade</p></li>
                                    <li><p id="atendimento"> Excelência de Atendimento</p></li>
                                    <li><p id="satisfacao"> Satisfação</p></li>
                                </ul>
                            </div>
                        </div>


                        
                  </section>
                  <section className="valores-sobre-nos valores-mobile">
                        <div className="visao-sobre visao-sobre-mobile">
                            <div className="visao-text">
                                <h1>
                                    Visão
                                </h1>
                                <p>Sermos reconhecidos como uma empresa que oferece produtos
                                 e serviços de qualidade que supera expectativas dos clientes.</p>
                            </div>
                            <img src={require('../../img/sobreNos/img2.jpg').default} />
                        </div>

                        <div className="missao-sobre missao-sobre-mobile">
                            <div className="missão-text">
                                <h1>
                                    Missão
                                </h1>
                                <p>Promover soluções e experiências inovadoras e 
                                    sustentáveis buscando sempre a melhor tecnologia para aplicação.</p>
                            </div>
                            <img src={require('../../img/sobreNos/img3.jpg').default} />
                        </div>

                        <div className="valores-sobre valores-sobre-mobile">
                            <div className="valores-text">
                                <h1>
                                    Valores
                                </h1>
                             
                                <ul>
                                    <li><p id="etica"> Ética</p></li>
                                    <li><p id="profissionalismo"> Profissionalismo</p></li>
                                    <li><p id="sustentabilidade"> Sustentabilidade</p></li>
                                    <li><p id="atendimento"> Excelência de Atendimento</p></li>
                                    <li><p id="satisfacao"> Satisfação</p></li>
                                </ul>
                            </div>
                            <img src={require('../../img/sobreNos/img4.jpg').default} />
                        </div>


                        
                  </section>
                   {/* SECTION  DUVIDAS, ELOGIOS */}
                   <section className="section-duvidas-elogios">

                <h1>Dúvidas, elogios e reclamações</h1>
                <h2>Fale conosco</h2>

                <div className="info-contatos">
                    <div className="text-infos">
                        <p>Telefone: +55 21 99822-5552</p>
                        <p>Email: contato@ftenergia.com.br</p>
                        <p>Whats: + 55 21 99822-5552</p>
                    </div>

                    <div className="box-contato-email">
                       <form method="POST" action="?">
                            <input type="string" placeholder="Nome" required></input>
                            <input type="email" placeholder="E-mail" required></input>
                            <textarea placeholder="Mensagem"></textarea>
                            <button type="submit">Enviar Mensagem</button>
                       </form>
                    </div>

                    <div className="siga-nos-sobre">
                        <h3>Siga-nos nas redes sociais</h3>
                        <a href="#" className="youtube icon"><i className="fab fa-youtube youtube fa-3x"></i></a>
                        <a href="#" className="instagram icon"><i className="fab fa-instagram fa-3x"></i></a>
                    </div>
                </div>

                </section>
            </div>
            <div className="wpp-fixo">
            <a  target="_blank" rel="noreferrer"  href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem?`}><i className="fab fa-whatsapp fa-2x wpp"></i></a>
            </div>
            <Footer/>
        </>
    )
}

export default Sobre;