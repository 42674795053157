import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.css';
//components



//pages
import Home from './pages/Home/index';
import Sobre from './pages/Sobre';
import Servicos from './pages/Servicos';
import Artigos from './pages/Artigos';
//router dom
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Instalacoes from './pages/Instalacoes';

ReactDOM.render(

    <Router>
      
      <Switch>
          <Route exact path="/" component={Home}  />
          <Route exact path="/sobre-nos" component={Sobre} />
          <Route exact path="/servicos" component={Servicos} />
          <Route exact path="/instalacoes" component={Instalacoes}/>
          <Route  path="/artigos/" component={Artigos}/>
        </Switch>
      
        
      </Router>

  ,
  document.getElementById('root')
);