/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom';

// scroll suave


const Header = () =>{
   return(
       <>
        <header>
            <div className="overlay">
                <div className="box-header">
                    <div className="tituloRay"> 
                        <h1 style={{'display': 'inline-block'}} className="ftMobile">FT Energia</h1>
                            {/* <i className="fas fa-bolt fa-5x ray"></i>  */}
                            <img src={require('../../img/logoft.png').default} width="150" className="logoFeia"  alt="logo"/>
                    
                    </div>
                    
                    <p>Utilize a energia solar e economize recursos naturais.</p>

                    <div id="btn-ativar"><img src="./img/arrow.gif" width="35"></img></div>
                </div>
            </div>
            
        </header>
        {/* // section msgEconomia */}
        <section className="msgEconomia">
            <h2 id="btn-rolar">
                ECONOMIA DE ATÉ 95% EM SUA FATURA DE ENERGIA
            </h2>
   
            <img src="./img/energia-solar.png" width="110" />
        </section>
        {/* <!--xx section msgEconomia xx--> */}
        </>
   )
}

export default Header;