/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        // <!-- FOOTER -->
 		<footer>
 			<div className="content-footer">
 				<div className="sobre">
					 <a className="titulo-footer">Sobre Nós</a>
 					<ul>
 						<li><Link className="link-footer" to="/sobre-nos">Missão</Link></li>
 						<li><Link className="link-footer" to="/sobre-nos">Visão</Link></li>
 						<li><Link className="link-footer" to="/sobre-nos">Valores</Link></li>
 						<li><Link className="link-footer" to="/sobre-nos">A Empresa</Link></li>
 					</ul>
 				</div>

 				<div className="servicos">
				 <a className="titulo-footer">Serviços</a>
 					<ul>
 						<li><Link className="link-footer" to="/servicos">Energia Solar</Link></li>
 						<li><Link className="link-footer" to="/servicos">Automação Industrial</Link></li>
 						<li><Link className="link-footer" to="/servicos">Instalações Elétricas</Link></li>
 						<li><Link className="link-footer" to="/servicos">Instrumentação Industrial</Link></li>
 						<li><Link className="link-footer" to="/servicos">Aumento de carga</Link></li>
 					</ul>
 				</div>

 				<div className="instalacoes">
				 <a className="titulo-footer">Instalações</a>
 					<ul>
 						<li><Link className="link-footer" to="/instalacoes">Magé</Link></li>
 						<li><Link className="link-footer" to="/instalacoes">Niterói</Link></li>
 						<li><Link className="link-footer" to="/instalacoes">Guapimirim</Link></li>
 						<li><Link className="link-footer" to="/instalacoes">São João de Meriti</Link></li>
 						<li><Link className="link-footer" to="/instalacoes">Itaborai</Link></li>
 					</ul>
 				</div>

 				<div className="blog">
				 <a className="titulo-footer">Artigos</a>
 					<ul>
 						<li><Link className="link-footer" to="">Casas de energia solar</Link></li>
 						<li><Link className="link-footer" to="">O que é energia solar</Link></li>
 						<li><Link className="link-footer" to="">Dicas de isolamento ecológico</Link></li>
 					</ul>
 				</div>

 				<div className="contato">
				 <a className="titulo-footer">Contato</a>
 					<ul>
 						<li><a className="link-footer" href={`https://api.whatsapp.com/send?phone=+5521998225552&text=Olá, tudo bem? gostaria de fazer um orçamento.`} rel="noreferrer" target="_blank">Fale conosco</a></li>
 					</ul>
 				</div>

 				<div className="siga-nos">
				 <a className="titulo-footer">Siga-nos</a>
 					<ul>
					 <a href="https://www.youtube.com/channel/UCAgxLCg-PpTOCiERia0nSGA" rel="noreferrer" target="_blank" className="youtube icon"><i className="fab fa-youtube youtube fa-2x"></i></a>
                     <a href="https://www.instagram.com/energiaft/" rel="noreferrer" target="_blank" className="instagram icon"><i className="fab fa-instagram fa-2x"></i></a>
 					</ul>
 				</div>

 			</div>
 			<span className="creditos">© Todos os direitos reservados | Desenvolvido por <a href={`https://api.whatsapp.com/send?phone=+5521986564067`} className="autor">Bruno dos Santos. </a></span>
 		</footer>
 	// <!-- XX FOOTER XX -->
    )
}

export default Footer;